/* PrimeNG */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";

/* Tailwind CSS */
@import "assets/theme/tailwind-light/theme.css";

.tlb--max-screen-height {
  max-height: calc(100vh - 220px);
}

p-editor {
  .p-editor-container {
    max-height: calc(100vh - 370px);

    .p-editor-toolbar {
      position: sticky;
      top: 0;
      z-index: 1;
      border-radius: 0;
    }

    .p-editor-content {
      .ql-editor {
        max-height: calc(100vh - 415px);
      }

      .ql-tooltip {
        &.ql-editing {
          z-index: 1000;
        }
      }
    }
  }
}

.tlb--editor-header {
  background: #fafafa;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-bottom: 0 none;
  border-radius: 0.375rem 0.375rem 0 0;
}

.p-orderlist {
  .p-orderlist-controls,
  .p-orderlist-filter-container {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .p-orderlist-header {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &.tlb--orderlist-with-filters-and-controls {
    .p-orderlist-list {
      max-height: calc(100vh - 480px);
      overflow-y: scroll;
    }
  }

  &.tlb--orderlist-without-filters-or-controls {
    max-height: calc(100vh - 320px);
    overflow-y: scroll;

    .p-orderlist-controls {
      display: none;
    }
  }
}

google-map {
  .map-container {
    min-height: 320px;
    height: calc(100vh - 360px) !important;
    width: 100% !important;
  }
}

.tlb--div-maximized-wrapper {
  position: absolute;
  inset: 0;
  z-index: 999;

  .tlb--div-maximized {
    position: absolute;
    inset: 20px;
    z-index: 1000;
    overflow-y: scroll;
    margin-bottom: 0;
  }
}

.p-message {
  &.p-message-error,
  &.p-message-info,
  &.p-message-success,
  &.p-message-warn {
    .p-message-close {
      flex-shrink: 0;
    }
  }

  .p-message-detail {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .p-message-summary {
    flex-shrink: 0;
  }
}
